<template>
    <Sidebar v-model:visible="$store.state.evaluation.display" position="full" :baseZIndex="1000" >
		<div class="p-grid">
			<div class="p-col-12 p-md-12 p-lg-3">
				<div class="ttitle">{{$t('Evaluation')}}</div>
			</div>
			<div class="p-col-12 p-md-12 p-lg-6">
				<InputText v-model="$store.state.evaluation.data.responsible" style="width: 100%;"  v-if="intern"/>	
				<strong  v-if="intern">{{$t('Responsible')}}</strong>
			</div>
			<div class="p-col-12 p-md-12 p-lg-3">
				<Button :label="$t('Process')" style="width: 30%;" icon="pi pi-save" class="p-button-secondary p-mr-2 p-mb-2" @click="Save(true)" :disabled="porc!=100"/>
				<Button :label="$t('Save')" style="width: 30%;" icon="pi pi-save" class="p-button-secondary p-mr-2 p-mb-2" @click="Save(false)"/>
				<Button :label="$t('Close')" style="width: 30%;" icon="pi pi-times" class="p-button-secondary p-mr-2 p-mb-2" @click="$store.state.evaluation.display = false"/>
				<ProgressBar :value="porc" style="width: 90%;"/>	
			</div>	
		</div>
		<TabView>
			<TabPanel v-for="(state, index) in $store.state.evaluation.answers" :key="state.key">				
				<template #header>
					<div>
						{{$t('Compliance')+' N° '+(index+1)}}
					</div>
					<div style="color: red;" v-if="state.answers.filter(val => val.rating == null).length>0">*</div>
				</template>	
				<div class="p-grid">
					<div class="p-col-12 p-md-12 p-lg-11 text-title">
						{{state['compliance_'+i18n.locale()]}}
					</div>
					<div class="p-col-12 p-md-12 p-lg-1">
						{{$t('Pending')}}: {{ state.answers.filter(val => val.rating == null).length}}	
					</div>
				</div>	
				<DataTable :value="state.answers" :paginator="false"  class="p-datatable-customers p-datatable-sm"
					scrollable dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" :scrollHeight="heigt_sc+'px'">
					<Column field="id" :header="$t('N°')" headerStyle="width: 40px">
						<template #body="slotProps">
							{{index+1}}.{{slotProps.index+1}}
						</template>
					</Column>
					<Column :field="i18n.locale()" :header="$t('Assessment Criteria')" />
					<Column field="id" :header="$t('Yes')+'/'+$t('No')" headerStyle="width: 60px">
						<template #body="slotProps">
							<ToggleButton v-model="slotProps.data.meets" :onLabel="$t('Yes')" :offLabel="$t('No')" style="background-color:white"/>
						</template>
					</Column>
					<Column field="id" :header="$t('Description')+'*'" headerStyle="width: 500px">
						<template #body="slotProps">
							<div class="p-grid">
								<div class="p-col-12">
									<Textarea v-model="slotProps.data.description" autoResize rows="3" style="width: 100%;"/>
								</div>
								<div class="p-col-8">
									{{$t('Action')}}: 
									<Dropdown v-model="slotProps.data.action" :options="$store.state.evaluation.action" :optionLabel="i18n.locale()" :placeholder="$t('Select')+'...'"/>
								</div>
								<div class="p-col-2">
									<FileUpload :chooseLabel="$t('PDF')" :uploadLabel="$t('PDF')" mode="basic" :name="img" :url="url+'/upload.php'"  accept="application/pdf" :maxFileSize="4000000" @upload="Upload(index,slotProps.index)" :auto="true"/>
								</div>
								<div class="p-col-2" v-if="slotProps.data.pdf">
									<Button :label="$t('View')" icon="pi pi-file-pdf" class="p-button-secondary p-mr-2 p-mb-2"  @click="ViewPDF(slotProps.data.pdf)"/>
								</div>
							</div>
						</template>
					</Column>
					<Column field="id" :header="$t('Rating')" headerStyle="width: 170px">
						<template #body="slotProps">
							<div v-for="opc in $store.state.evaluation.scale" :key="opc.key" class="p-ml-1 p-mr-1 p-mb-1">
								<Tag :style="'justify-content: start;align-items: center; width: 100%;background-color:#'+opc.color">
									<RadioButton v-model="slotProps.data.rating" :inputId="opc.key" name="dynamic" :value="opc.id" @change="Calc()"/>
									<label :for="opc.key" class="p-ml-2 p-mr-2" :style="'color: #'+opc.colortext ">{{ opc[i18n.locale()] }} ({{ opc.score }})</label>
								</Tag>
							</div>
						</template>
					</Column>
				</DataTable>
			</TabPanel>
		</TabView>	
    </Sidebar>
</template>

<script>
	import API from "../service/API";
	import { useI18nPlugin } from '@unify/vuex-i18n';
	import Token from "uuid-token-generator";
	
	export default {
		props: {
            sca: {
				type: Object,
				default: null,
			},
            intern: {
				type: Boolean,
				default: false,
			},

		},
		data() {
			return {
				i18n: null,
				url: null,
				img: null,
				porc: 0,
				heigt_sc: 0,
			}
		},
		productService: null,
		created() {
			this.i18n = useI18nPlugin();
			this.url = process.env.VUE_APP_API;
			const tokgen = new Token(256);
			this.img = tokgen.generate();
			if(this.$store.state.screen<=728){
				this.heigt_sc =  380;
			} else {
				this.heigt_sc =  500;
			}
			this.Calc();
		},
		mounted() {
			
		},
		methods: {
			Save(cas) {
				var Consult = new API('Evaluation','Internal');
				if(!this.intern){
					Consult = new API('Evaluation','External');
				}
				let product = {};
				product = this.$store.state.evaluation.data;
				product.answers = this.$store.state.evaluation.answers;
				product.process = cas;
                Consult.Process('UPD',{
					inf: product
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					this.$toast.add(Consult.Mens(response.result,this.i18n.locale()));
					if (response.result) {
                        this.$store.state.evaluation.display = false;
						this.onSave();
					} 				
				});
			},
			onSave(event) {
				this.$emit('save-close', event);
			},
			ViewPDF(document){
				window.open(this.url+'/upload/'+document, '_blank');
			},
			Upload(comp,ind){
				var Consult = new API('Evaluation','Internal');
				if(!this.intern){
					Consult = new API('Evaluation','External');
				}
				Consult.Process('Upload',{
					inf: this.img,
				}).then(response => {
					//this.$store.state.error = response;
					if(response.result){
						this.$store.state.evaluation.answers[comp].answers[ind].pdf = response.result;
					} 
					const tokgen = new Token(256);
					this.img = tokgen.generate();
					this.$store.commit('Loading');
				});
			},
			Calc(){
				let porc = 0;
				let total = 0;
				let answers = this.$store.state.evaluation.answers;
				answers.forEach(compliance => {
					compliance.answers.forEach(element => {
						if(element.rating != null){
							porc =  parseInt(porc) + 1;
						}
						total = parseInt(total) + 1;
					});
				});
				if(porc>0){
					this.porc = (parseInt(porc)*100/parseInt(total)).toFixed(1);
				} else {
					this.porc = 0;
				}
			}
		}
	}
</script>
<style scoped lang="scss">
.ttitle{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
    padding: 1em 0em 1em 0.5em;
}
.text-title{
    font-family: 'Rajdhani', sans-serif;
    font-size: calc(0.7rem + 1vw);
    font-weight: 600;
    color: #2b3b80;
}

.p-datatable .p-column-filter {
	display: none;
}

.table-header {
	display: flex;
	justify-content: space-between;
}

::v-deep(.p-datatable.p-datatable-customers) {
	.p-datatable-header {
		padding: 1rem;
		text-align: left;
		font-size: 1.5rem;
	}

	.p-paginator {
		padding: 1rem;
	}

	.p-datatable-thead > tr > th {
		text-align: left;
	}

	.p-datatable-tbody > tr > td {
		cursor: auto;
	}

	.p-dropdown-label:not(.p-placeholder) {
		text-transform: uppercase;
	}
}

/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

.customer-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-qualified {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-unqualified {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-negotiation {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.status-new {
		background: #B3E5FC;
		color: #23547B;
	}

	&.status-renewal {
		background: #ECCFFF;
		color: #694382;
	}

	&.status-proposal {
		background: #FFD8B2;
		color: #805B36;
	}
}

@media (max-width: 640px) {

	
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
}

.orders-subtable {
	padding: 1rem;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.order-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.order-delivered {
		background: #C8E6C9;
		color: #256029;
	}

	&.order-cancelled {
		background: #FFCDD2;
		color: #C63737;
	}

	&.order-pending {
		background: #FEEDAF;
		color: #8A5340;
	}

	&.order-returned {
		background: #ECCFFF;
		color: #694382;
	}
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}


				}
			}
		}
	}
}
</style>